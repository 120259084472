import {useState}  from "react";
import './css/Style.css';
const App =()=>{
 const [data,setData] = useState('kajal');
 function test() {
    setData('Goutam');

 }
    return(
        <div className="App">
            <h1>Name :  {data} </h1>
            <button onClick={test}>Click Me</button>
        </div>
    )
}
export default App